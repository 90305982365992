@import '@vuxx/hounds/scss/abstracts/variables';

.logo {
  width: 12.5rem;
}

.login-page {
  padding-top: 1.5rem;
  font-size: 1.2em;
}

.main-container {
  width: 18.75rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}

.password-reset {
  margin-bottom: 1.5rem;
  font-weight: 600;
  text-align: end;
}

.password-reset-tip {
  margin-bottom: 1.5rem;
  font-weight: 500;
}

.password-tip h4 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1em;
}

.password-reset a {
  color: #0094FF !important;
  text-decoration: underline;
}

@media (prefers-color-scheme: dark) {
  .enable-dark-mode .password-reset-tip{
    color: $color-text-light;
  }

  .enable-dark-mode .login-page{
    background-color: $color-background-dark;
  }

  @media (prefers-color-scheme: dark) {
    .enable-dark-mode .logo {
      content: url("/public/images/logo-box-main-dark.png");
      width: 12.5rem;
    }
  }

}
